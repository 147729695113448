import { error } from "console";
import api from "../api"
import { Col, Container, Row } from "react-bootstrap";
import TaskSlotCard from "./TaskSlotCard";
import TaskSlotWithTask from "../entity/TaskSlotWithTask";

export default function TaskSlotsPage(props: { asMaker: boolean }) {
    const urlSubpath = props.asMaker ? 'my-as-maker' : 'my-as-taker';
    const title = props.asMaker?'What my workers are doing':'What I need to do';
    var slots = api.useGetResult<TaskSlotWithTask[]>('/taskboard/slots/' + urlSubpath);

    function reloadData(){
        slots.reload();
    }

    return <>
        <Container>
            <Row className="mt-3" style={{ marginBottom: -10 }}>
                <h2>{title}</h2>
            </Row>
            {slots.isLoading && <Row className="mt-3"><h4>Loading data...</h4></Row>}
            {slots.error && <Row className="mt-3"><h4>Error: {slots.error + ''}</h4></Row>}
            {slots.isSuccess && slots.data!.length == 0 && <Row className="mt-3"><h4>Lonely here. {props.asMaker?'Try creating some tasks for workers to do!':'Try making requests to tasks in taskboard!'}</h4></Row>}
            {slots.isSuccess && slots.data!.length>0 && <Row xs={1} lg={2}>
                {slots.data!.map(q => (
                    <Col className="mt-3">
                        <TaskSlotCard key={q.Slot.Id} slot={q.Slot} task={q.Task} onChanged={reloadData} showResultsInHeader />
                    </Col>
                ))}
            </Row>}
        </Container>
    </>
}