import { DateTime, Interval } from "luxon";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SharedResource from "../entity/SharedResource";
import api from "../api";
import SpinnableText from "../components/SpinnableText";

export default function BookingConfirmationModal(props:{isVisible:boolean, selectedInterval:Interval, resource:SharedResource, onClose:()=>void, onSuccess:()=>void}){
    const [isClosing, setIsClosing] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    function handleConfirmClick(){
        setIsLoading(true);
        api.api.performApiRequest('/planner/reservations/create', 'POST', {
            ResourceId:props.resource.Id,
            From:props.selectedInterval.start?.toISO(),
            To:props.selectedInterval.end?.toISO()
        })
        .then(res=>{
            //alert('success');
            setIsSuccess(true);
            setIsClosing(true);
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        });
    }
    return <Modal show={props.isVisible && !isClosing} onHide={() => setIsClosing(true)} onExited={isSuccess?props.onSuccess:props.onClose}>
        <Modal.Header closeButton>
            Booking confirmation
        </Modal.Header>
        <Modal.Body>
            <div>Booking <b>{props.resource.Name}</b> on land <b>#{props.resource.LandNo}</b></div>
            <div>From:<b>{props.selectedInterval.start?.toLocaleString(DateTime.DATETIME_FULL)}</b></div>
            <div>To:<b>{props.selectedInterval.end?.toLocaleString(DateTime.DATETIME_FULL)}</b></div>
            <div>Confirm?</div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" disabled={isLoading} onClick={()=>setIsClosing(true)}>Cancel</Button>
            <Button variant="primary" disabled={isLoading} onClick={handleConfirmClick}>
                <SpinnableText text="Confrm" isSpinning={isLoading}/>
            </Button>
        </Modal.Footer>
    </Modal>

}