import { ReactElement, useState } from "react";
import { Modal, Form, Row, Col, Button, ListGroup } from "react-bootstrap";
import DropdownWithSearch from "../DropdownWithSearch";
import SpinnableText from "../components/SpinnableText";
import api from "../api";
import EnergyItem from "../entity/EnergyItem";
import { count } from "console";

export default function EnergyCalculationModal(props: { isVisible: boolean, onClose: () => void, requiredEnergy:number}){
    const [isClosing, setIsClosing] = useState(false);
    const energyReq = api.useGetResult<EnergyItem[]>('/resources/energy');
    const items: any[] = energyReq.isSuccess ? energyReq.data ?? [] : [];
    const placeholderText = energyReq.isLoading ? "Loading items" : (energyReq.error ? energyReq.error + '' : 'Select energy source');
    const [selectedItem, setSelectedItem] = useState<EnergyItem|null>(null);
    function handleClosing(){
        setIsClosing(true);
    }
    function renderItem(item: EnergyItem): ReactElement {
        return <><img className="item-icon me-2" src={`/item_images/${item.Id}.png`}/>{item.Name + " (" + item.EnergyGain + '/item)'}</>
    };
    function filterFunction(item:EnergyItem, filter:string){
        return item.Name.toLowerCase().includes(filter.toLowerCase());
    }
    function handeItemChange(key:string){
        const item = energyReq.data!.find(q=>q.Id===key)!;
        setSelectedItem(item);
    }
    const neededQty = selectedItem==null?null:props.requiredEnergy/selectedItem.EnergyGain;
    return(
        <Modal show={props.isVisible && !isClosing} onHide={handleClosing} onExited={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Calculate energy</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <Form onSubmit={e => e.preventDefault()} className="p-2">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Select energy source</Form.Label>
                        <DropdownWithSearch
                            items={items}
                            itemRender={renderItem}
                            itemToKey={q => q.Id}
                            itemFilter={filterFunction}
                            placeholderText={placeholderText}
                            disabled={!energyReq.isSuccess}
                            onSelect={handeItemChange}
                        />
                    </Form.Group>
                </Form>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item variant="secondary">Needed energy</ListGroup.Item>
                    <ListGroup.Item>{props.requiredEnergy}</ListGroup.Item>
                    {neededQty && <>
                        <ListGroup.Item variant="secondary">{Math.ceil(neededQty)}x{selectedItem?.Name}</ListGroup.Item>
                        <ListGroup.Item>Gives {Math.ceil(neededQty) * selectedItem!.EnergyGain} of {props.requiredEnergy} energy</ListGroup.Item>
                        <ListGroup.Item variant="secondary">{Math.floor(neededQty)}x{selectedItem?.Name}</ListGroup.Item>
                        <ListGroup.Item>Gives {Math.floor(neededQty) * selectedItem!.EnergyGain} of {props.requiredEnergy} energy</ListGroup.Item>
                    </>}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClosing}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}