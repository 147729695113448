import { Button, Modal } from "react-bootstrap";
import ResourceReservation from "../entity/ResourceReservation";
import UserInfo from "../entity/UserInfo";
import { DateTime } from "luxon";
import { useContext, useState } from "react";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
interface Slot {
    times: DateTime[],
    reservation: ParsedResourceReservation | null
}
function OccupiedTimeframe(props: { children: React.ReactNode, widthPercent: number, reservation: ParsedResourceReservation, onReservationClicked: (reservation:ResourceReservation) => void }) {
    return <div 
    onClick={()=>{
        if(!props.reservation.isCurrentSelection){
            props.onReservationClicked(props.reservation.reservation);
        }
    }}
    className={"timeline-range occupied " + (props.reservation.isCurrentSelection?"selection":(props.reservation.isMyRange?"my-range":""))} 
    style={{ width: `${props.widthPercent}%` }}>
        <div className="player-name">{props.reservation.reservation.User.Username}</div>
        <div className="d-flex">{props.children}</div>
    </div>
}
function TimelineSlot(props: { time: DateTime, occupied: boolean, isSelection:boolean, isMyRange:boolean, widthPercent: number, onClick: (time: DateTime) => void }) {

    return <div className={"timeline-slot " + (props.occupied ? "occupied" : "")} style={{ width: `${props.widthPercent}%` }}>
        <Button
            disabled={props.occupied}
            variant={props.occupied && !props.isMyRange ? "secondary" : (props.isSelection?"success":"primary")}
            className="timeline-button"
            onClick={() => props.onClick(props.time)}
        >{(props.time.minute + "").padStart(2, "0")}</Button>
    </div>
}
function TimelineRow(props: { reservations: ParsedResourceReservation[], startTime: DateTime, intervalSecs: number, onClick: (time: DateTime) => void, onReservationClicked: (reservation:ResourceReservation) => void }) {
    const intervalSecs = props.intervalSecs;
    var points: DateTime[] = [];
    var curPt = props.startTime;
    while (curPt.hour == props.startTime.hour) {
        points.push(curPt);
        curPt = curPt.plus({ seconds: props.intervalSecs });
    }
    var slots: Slot[] = [];
    points.forEach(q => {
        var foundReservation = props.reservations.find(r => r.from <= q && q < r.to);
        if (foundReservation) {
            if (slots.length > 0) {
                var lastAdded = slots[slots.length - 1];
                if (lastAdded.reservation) {
                    //if (lastAdded.reservation?.reservation.User.Id == foundReservation.reservation.User.Id && lastAdded.reservation?.isCurrentSelection == foundReservation.isCurrentSelection) {
                    if(lastAdded.reservation?.reservation.Id == foundReservation.reservation.Id){
                        //same user
                        lastAdded.times.push(q);
                        return;
                    }
                }
            }
            //new reserved time
            slots.push({
                times: [q],
                reservation: foundReservation
            });
            return;
        }
        //not reserved
        slots.push({
            times: [q],
            reservation: null
        });
    });

    return <div className="timeline-row d-flex align-items-end mb-3">
        <div className="timeline-range occupied" style={{ width: 0, overflow: "clip" }}>
            <div className="player-name">A</div>
            <div className="d-flex"><Button className="timeline-button">XX</Button></div>
        </div>
        <Button disabled variant="basic" className="px-0 me-1" style={{ borderColor: "transparent", width:40 }}>{(props.startTime.hour + '').padStart(2, '0')}:</Button>
        <div className="d-flex flex-fill align-items-end ">
        {slots.map(q => <>
            {!q.reservation && <TimelineSlot
                time={q.times[0]}
                occupied={false}
                isMyRange={false}
                widthPercent={100 / points.length}
                onClick={props.onClick}
                isSelection={false}
            />}
            {q.reservation && <OccupiedTimeframe onReservationClicked={props.onReservationClicked} widthPercent={100 * q.times.length / points.length} reservation={q.reservation!}>
                {q.times.map(t => <TimelineSlot
                    time={t}
                    occupied={!q.reservation?.isCurrentSelection}
                    isMyRange={q.reservation?.isMyRange??false}
                    widthPercent={100 / q.times.length}
                    onClick={props.onClick}
                    isSelection={q.reservation?.isCurrentSelection??false}
                />
                )}
            </OccupiedTimeframe>}
        </>)}
        </div>
        {/* {points.map(q=><TimelineSlot
            num={q.minute}
            occupied={false}
            widthPercent={100 / points.length}
        />)} */}

    </div>
}

class ParsedResourceReservation {
    public readonly reservation: ResourceReservation;
    public readonly from: DateTime;
    public readonly to: DateTime;
    public readonly isCurrentSelection: boolean;
    public readonly isMyRange:boolean;
    public constructor(reservation: ResourceReservation, isCurrentSelection: boolean = false, isMyRange:boolean = false) {
        this.reservation = reservation;
        this.from = DateTime.fromISO(reservation.From);
        this.to = DateTime.fromISO(reservation.To);
        this.isCurrentSelection = isCurrentSelection;
        this.isMyRange = isMyRange;
    }

}
export interface ResourceTimelineProps{
    //timestamp = time when results were loaded
    timestamp : DateTime,
    reservations: ResourceReservation[],
    onIntervalSelected:(from:DateTime|null, to:DateTime|null)=>void,
    intervalSecs : number,
    onReservationClicked: (reservation:ResourceReservation) => void
}
export default function ResourceTimeline(props: ResourceTimelineProps) {
    const userReq = useContext(LoggedUserInfoContext);

    const parsedReservations = props.reservations.map(q => new ParsedResourceReservation(q,false, q.User.Id==userReq.data?.Id));
    const startingPoint = DateTime.now().startOf('hour');
    const hours: DateTime[] = [];
    for (var h = 0; h < 24; h++) {
        hours.push(startingPoint.plus({ hours: h }));
    }
    const [curFrom, setCurFrom] = useState<DateTime | null>(null);
    const [curTo, setCurTo] = useState<DateTime | null>(null);
    const [snapshotTimestamp, setSnapshotTimestamp] = useState<DateTime>(props.timestamp);
    if(props.timestamp != snapshotTimestamp){
        //new snapshot is being loaded
        setSnapshotTimestamp(props.timestamp);
        setCurFrom(null);
        setCurTo(null);
        props.onIntervalSelected(null,null);
    }

    let curReservation: ParsedResourceReservation | null = null;
    if (curFrom) {
        if (curTo) {
            //one slot reservation
            curReservation = new ParsedResourceReservation({
                Id: "",
                From: curFrom.toISO()!,
                To: curTo.plus({ seconds: props.intervalSecs }).toISO()!,
                ResourceId: "",
                User: userReq.data!
            }, true);
        }
        else{
            curReservation = new ParsedResourceReservation({
                Id:"",
                From:curFrom.toISO()!,
                To:curFrom.plus({seconds:props.intervalSecs}).toISO()!,
                ResourceId:"",
                User:userReq.data!
            },true);
        }
    }
    let reservationsPlusSelected = parsedReservations;
    if(curReservation){
        reservationsPlusSelected = reservationsPlusSelected.concat([curReservation]);
    }

    function handleTimeClick(time: DateTime) {
        if (curFrom == null) {
            //setting start part
            setCurFrom(time);
            props.onIntervalSelected(time, null);
        }
        else {
            if (curTo == null) {
                let curFromVar = curFrom;
                //setting end part
                if (time < curFromVar) {
                    //selected point before curFrom, swap
                    const tmp = curFromVar;
                    setCurFrom(time);
                    curFromVar = time;
                    time = tmp;
                    //another button clicked
                    // alert("End time must be after start time");
                    // setCurFrom(null);
                    // return;
                }
                if (time != curFromVar) {
                    //another button clicked
                    if (parsedReservations.some(q => curFromVar < q.to && q.from < time)) {
                        alert("Selected interval contains occupied time");
                        setCurFrom(null);
                        return;
                    }
                }
                setCurTo(time);
                props.onIntervalSelected(curFromVar, time);
            } else {
                //clear selection
                setCurTo(null);
                setCurFrom(null);
            }
        }
    }
    return <>
        <div className="d-flex justify-content-center timeline-splitter">Today</div>
        {hours.map(t => <>
            {t.hour == 0 && t.day != DateTime.now().day && <div className="d-flex justify-content-center timeline-splitter">Tomorrow</div>}
            <TimelineRow
                intervalSecs={props.intervalSecs}
                reservations={reservationsPlusSelected}
                startTime={t}
                onClick={handleTimeClick}
                onReservationClicked={props.onReservationClicked}
            />
        </>)}
    </>
}