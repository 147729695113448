import { sha256 } from "js-sha256";
import React, { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import isDebug from "./isDebug";
import { SiweMessage } from "siwe";
import { DateTime } from "luxon";
export class ApiError extends Error {
    public readonly statusCode: number;
    public constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }
}
// export function sha256(string: string) {
//     const utf8 = new TextEncoder().encode(string);
//     return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
//         const hashArray = Array.from(new Uint8Array(hashBuffer));
//         const hashHex = hashArray
//             .map((bytes) => bytes.toString(16).padStart(2, '0'))
//             .join('');
//         return hashHex;
//     });
// }
class Api {
    private static instance: Api;
    private constructor() { 
        if(!isDebug){
            //change variables
            console.log("Mode: production")
            this.baseUrl = "https://" + window.location.hostname + "/api";
            this.authUrl = "https://" + window.location.hostname + "/auth"
        }else{
            console.log("Mode: debug")
        }
    }
    public static getInstance(): Api {
        if (!Api.instance) {
            Api.instance = new Api();
        }
        return Api.instance;
    }

    public readonly baseUrl: string = "http://" + window.location.hostname + ":80/api"
    public readonly authUrl: string = "http://" + window.location.hostname + ":80/auth"
    public readonly authCookieName: string = 'auth_token'
    public readonly cookies: Cookies = new Cookies(null, { path: '/' })

    public authToken?: string | null;
    public setAuthToken?: React.Dispatch<React.SetStateAction<string | null>>;
    private getUrl(endpoint: string) {
        return this.baseUrl + endpoint;
    }

    public performApiGetRequest(path: string) {
        return new Promise<any>((resolve, reject) => {
            if (this.authToken == null) {
                reject(new Error('not logged in'));
            }
            fetch(this.getUrl(path), {
                headers: {
                    "AUTH_TOKEN": this.authToken ?? ''
                }
            })
                .then((res) => {
                    if (!res.ok) {
                        if (res.status === 401) {
                            //this means that session is not valid anymore
                            this.cookies.remove(this.authCookieName)
                            this.setAuthToken?.(null);
                        }
                        throw new ApiError('status: ' + res.status, res.status);
                    }
                    return res.json();
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    public performApiRequest(path: string, method: string = "GET", payload: any = null) {
        return new Promise<any>((resolve, reject) => {
            if (this.authToken == null) {
                reject(new Error('not logged in'));
            }
            var init: any = {
                headers: {
                    "AUTH_TOKEN": this.authToken ?? ''
                },
                method: method
            }
            if (payload != null) {
                init.body = JSON.stringify(payload);
            }
            fetch(this.getUrl(path), init)
                .then((res) => {
                    if (!res.ok) {
                        if (res.status === 401) {
                            //this means that session is not valid anymore
                            this.cookies.remove(this.authCookieName)
                            this.setAuthToken?.(null);
                        }
                        return res.text().then(text => {
                            throw new ApiError(`status: ${res.status}: ${text}`, res.status);
                        });
                    }
                    if (res.bodyUsed) {
                        return res.json();
                    }
                    return {};
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        })
    }
    //call this function to perform get call to api
    public requestSiweMessage(address:string) : Promise<any>{
        return fetch(this.authUrl + "/start-siwe/" + address)
        .then(res=>{
            if (!res.ok) {
                return res.text().then(text => {
                    throw new ApiError(`Code ${res.status}: ${text}`, res.status);
                });
            }
            // res.text().then(text=>{
            //     alert(text);
            // })
            // return res.text().then(text=>{
            //     console.log(text);
            //     return JSON.parse(text);
            // });
            return res.json();
        });
    }
    public submitSiweSignature(nonce:string, signature:string){
        var payload: any = {
            MessageNonce:nonce,
            Signature:signature
        };
        return fetch(this.authUrl + "/validate-siwe",{
            method: "POST",
            body: JSON.stringify(payload)
        })
        .then(res=>{
            if (!res.ok) {
                return res.text().then(text => {
                    throw new ApiError(`Code ${res.status}: ${text}`, res.status);
                });
            }
            return res.json();
            //should set cookie here!
        })
        .then(res=>{
            const expTime = new Date(res.ValidUntil);
            this.cookies.set(this.authCookieName, res.Token, {
                expires: expTime
            });
            this.setAuthToken?.(res.Token);//this will invoke rerender
        })
    }
    public loginWithPassword(address: string, password: string) {
        return new Promise((resolve, reject) => {
            var passwordHash = sha256(password);
            var payload: any = {
                Address: address,
                Password: passwordHash
            };
            fetch(this.authUrl + "/login-with-password", {
                method: "POST",
                body: JSON.stringify(payload)
            })
                .then(res => {
                    if (!res.ok) {
                        return res.text().then(text => {
                            throw new ApiError(`Code ${res.status}: ${text}`, res.status);
                        });
                    }
                    return res.json();
                })
                .then(res => {
                    this.cookies.set(this.authCookieName, res.Token, {
                        maxAge: 60 * 60 * 24 * 30
                    });
                    this.setAuthToken?.(res.Token);//this will invoke rerender
                    resolve(1);
                })
                .catch(err => reject(err))
        });
    }
    public logOut() {
        this.cookies.remove(this.authCookieName);
        this.setAuthToken?.(null);
    }
    //call this function on top of App
};
export interface ApiDataHandle<T> {
    /**Request response data */
    data: T | null,
    /**Indicates if loading is in process */
    isLoading: boolean,
    /**Contains request error if any */
    error: any,
    /**!isLoading & !error */
    isSuccess: boolean,
    /**time when request was loaded */
    timestamp: DateTime|null,
    /**Starts loading data again */
    reload: () => void,
    reloadWithCallback: (reloadFinishedCallback: () => void) => void
}
const api = {
    api: Api.getInstance(),
    useGetResult: function <DataType>(path: string): ApiDataHandle<DataType> {
        const [data, setData] = useState<DataType | null>(null);
        const [isLoading, setIsLoading] = useState(true);
        const [error, setError] = useState<any>(null);
        const [timestamp, setTimestamp] = useState<DateTime|null>(null);
        function PerformRequest(reloadFinishedCallback: () => void) {
            //setIsLoading(true);
            setError(null);
            Api.getInstance().performApiGetRequest(path)
                .then(res => {
                    setData(res);
                    setIsLoading(false);
                    setError(null);
                    setTimestamp(DateTime.now());
                    reloadFinishedCallback();
                })
                .catch(err => {
                    setError(err);
                    console.log(err);
                    setIsLoading(false);
                    setTimestamp(DateTime.now());
                    reloadFinishedCallback();
                });
        }
        useEffect(() => PerformRequest(() => { }), []);
        return { 
            data,
            isLoading,
            error,
            timestamp,
            reload: () => PerformRequest(() => { }),
            isSuccess: !isLoading && !error, reloadWithCallback: PerformRequest
         };
    },

    useApi: function () {
        const [authToken, setAuthToken] = useState<string | null>(api.api.cookies.get(api.api.authCookieName));
        api.api.authToken = authToken;
        api.api.setAuthToken = setAuthToken;
    }
}
export default api;