import { Button, Col, Container, Navbar, Row } from "react-bootstrap";
import api from "../api"
import TaskCard from "./TaskCard";
import CraftingTaskWithStats from "../entity/CraftingTaskWithStats";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import { useContext, useState } from "react";
import UserGuildRole from "../entity/UserGuildRole";
import CreateTaskModal from "./CreateTaskModal";

export default function TaskboardPage() {
    const userReq = useContext(LoggedUserInfoContext);
    const tasks = api.useGetResult<CraftingTaskWithStats[]>('/taskboard/tasks');
    const [createTaskModalVisible, setCreateTaskModalVisible] = useState(false);

    return <>
        <Container>
            <Navbar style={{marginBottom:-20}}>
                <Container fluid className="p-0">
                <Navbar.Brand><h2>Taskboard</h2></Navbar.Brand>
                <div >
                    {userReq.data?.Roles.includes(UserGuildRole.TaskMaker) && <Button onClick={()=>setCreateTaskModalVisible(true)}>Create task</Button>}
                </div>
                </Container>
            </Navbar>
            {tasks.isLoading && <Row className="mt-3"><h4>Loading data...</h4></Row>}
            {tasks.error && <Row className="mt-3"><h4>Error: {tasks.error + ''}</h4></Row>}
            {tasks.isSuccess && tasks.data!.length == 0 && <Row className="mt-3"><h4>Lonely here. Maybe someone will create a task someday...</h4></Row>}
            {tasks.isSuccess && tasks.data!.length>0 && <Row xs={1} lg={2}>
                {tasks.data!.map(t => (
                    <Col className="mt-3">
                        <TaskCard task={t} />
                    </Col>
                ))}
            </Row>}
        </Container>
        {createTaskModalVisible && 
        <CreateTaskModal 
        isVisible={createTaskModalVisible} 
        onClose={()=>setCreateTaskModalVisible(false)} 
        onCreated={()=>tasks.reload()} />}
    </>
}