import { Button, Dropdown, Form, Modal, NavDropdown, Spinner } from "react-bootstrap";
import api from "../api";
import SpinnableText from "../components/SpinnableText";
import UserInfo from "../entity/UserInfo";
import { useContext, useState } from "react";
import { sha256 } from "js-sha256";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import SetPasswordModal from "../UsersPage/SetPasswordModal";


export default function HeaderUserButton() {
    //const user = api.useGetResult<UserInfo>("/users/current");
    const userReq = useContext(LoggedUserInfoContext);
    const [setPasswordModalVisible, setSetPasswordModalVisible] = useState(false);
    return <>
        <NavDropdown title={<>
            {userReq.isLoading && <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />}
            {!userReq.isLoading && <>{userReq.data?.Username}</>}
        </>}>
            {/* <Dropdown.Toggle>

            </Dropdown.Toggle>
            <Dropdown.Menu> */}
            <NavDropdown.Item active={false} disabled={!userReq.isSuccess} onClick={() => setSetPasswordModalVisible(true)}>{userReq.data?.HasPassword?"Change":"Set"} password</NavDropdown.Item>
            <NavDropdown.Item active={false} onClick={() => api.api.logOut()}>Logout</NavDropdown.Item>
            {/* </Dropdown.Menu> */}
        </NavDropdown>
        <SetPasswordModal
                isVisible={setPasswordModalVisible}
                onClose={() => { setSetPasswordModalVisible(false) }}
                onSuccess={() => { setSetPasswordModalVisible(false) }}
                userId={userReq.data?.Id??"not loaded"}
            />
    </>
}