import EnergyIcon from "../img/hud_icon_energy.png";
import { Badge, Button, Card, CardText, ListGroup } from "react-bootstrap";
import TaskSlot from "../entity/TaskSlot";
import CraftingTask from "../entity/CraftingTask";
import TaskSlotStatus, { assignableStatusMaker, assignableStatusTaker } from "../entity/TaskSlotStatus";
import { useContext, useState } from "react";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import SpinnableText from "../components/SpinnableText";
import api from "../api";
import EnergyCalculationModal from "./EnergyCalculationModal";

interface TaskSlotCardProps {
    task: CraftingTask,
    slot: TaskSlot,
    showResultsInHeader?:boolean
    onChanged: () => void
}
function TaskSlotCardStatus(props: { status: TaskSlotStatus, makerMode: boolean }) {
    const makerHighlightedStatuses = [TaskSlotStatus.Pending, TaskSlotStatus.Done];
    const takerHighlightedStatuses = [TaskSlotStatus.Accepted];
    const secondaryColorStatuses = [TaskSlotStatus.Closed, TaskSlotStatus.Rejected, TaskSlotStatus.Cancelled];

    let bg = 'info';
    if (secondaryColorStatuses.includes(props.status)) {
        bg = 'secondary'
    } else {
        if (props.makerMode) {
            if (makerHighlightedStatuses.includes(props.status)) {
                bg = 'warning';
            }
        } else {
            if (takerHighlightedStatuses.includes(props.status)) {
                bg = 'warning';
            }
        }
    }
    // const colorMap = new Map<TaskSlotStatus, string>([
    //     [TaskSlotStatus.Pending, 'warning'],
    //     [TaskSlotStatus.Accepted, 'success'],
    //     [TaskSlotStatus.InProgress, 'info'],
    //     [TaskSlotStatus.Done, 'warning'],
    //     [TaskSlotStatus.Closed, 'success'],
    //     [TaskSlotStatus.Rejected, 'danger'],
    //     [TaskSlotStatus.Cancelled, 'danger'],
    //   ]);
    return <Badge bg={bg}>{TaskSlotStatus[props.status]}</Badge>;
}
function TaskSlotCardActionButton(props: { statusToSet: TaskSlotStatus, isDisabled: boolean, currentlyApplyingStatus: TaskSlotStatus | null, onClick: (status: TaskSlotStatus) => void }) {
    const isLoading = props.currentlyApplyingStatus === props.statusToSet;
    const textMap = new Map<TaskSlotStatus, string>([
        //[TaskSlotStatus.Pending, '----'],
        [TaskSlotStatus.Accepted, 'Accept'],
        [TaskSlotStatus.InProgress, 'In progress'],
        [TaskSlotStatus.Done, 'Done'],
        [TaskSlotStatus.Closed, 'Close'],
        [TaskSlotStatus.Rejected, 'Reject'],
        [TaskSlotStatus.Cancelled, 'Cancel'],
    ]);
    const colorMap = new Map<TaskSlotStatus, string>([
        //[TaskSlotStatus.Pending, 'primary'],
        [TaskSlotStatus.Accepted, 'success'],
        [TaskSlotStatus.InProgress, 'success'],
        [TaskSlotStatus.Done, 'success'],
        [TaskSlotStatus.Closed, 'success'],
        [TaskSlotStatus.Rejected, 'danger'],
        [TaskSlotStatus.Cancelled, 'danger'],
    ]);
    return <>
        <Button onClick={()=>props.onClick(props.statusToSet)} disabled={props.isDisabled} variant={colorMap.get(props.statusToSet)}>
            <SpinnableText isSpinning={isLoading} text={textMap.get(props.statusToSet) ?? "---"} />
        </Button>{' '}
    </>
}
export default function TaskSlotCard(props: TaskSlotCardProps) {
    const userReq = useContext(LoggedUserInfoContext);
    const slot = props.slot;
    const task = props.task;
    const relatedToLoggedUser = slot.MakerUser.Id === userReq.data?.Id || slot.TakerUser.Id === userReq.data?.Id;

    

    const isMakerActions = slot.MakerUser.Id == userReq?.data?.Id;
    const isTakerActions = slot.TakerUser.Id == userReq?.data?.Id;
    let statusesToAssign:TaskSlotStatus[] = [];
    if(isMakerActions){
        statusesToAssign.push(...assignableStatusMaker.get(slot.Status)??[]);
    }
    if(isTakerActions){
        statusesToAssign.push(...assignableStatusTaker.get(slot.Status)??[]);
    }
        
    const [isActionLoading, setIsActionLoading] = useState(false);
    const [currentApplyingStatus, setCurrentApplyingStatus] = useState<TaskSlotStatus|null>(null);
    const [isCalculateEnergyModalVisible,setIsCalculateEnergyModalVisible] = useState(false);
    if(currentApplyingStatus == slot.Status){
        //status update success
        setIsActionLoading(false);
        setCurrentApplyingStatus(null);
    }
    function applyStatus(status: TaskSlotStatus) {
        setIsActionLoading(true);
        setCurrentApplyingStatus(status);
        api.api.performApiRequest(`/taskboard/slots/${slot.Id}/set-status`, 'PATCH', {NewStatus:TaskSlotStatus[status]})
        .then(res=>{
            //not done here, list needs to refresh
            // setIsActionLoading(false);
            // setCurrentApplyingStatus(null);
            props.onChanged();
        })
        .catch(err=>{
            alert(err);
            setIsActionLoading(false);
            setCurrentApplyingStatus(null);
        });
    }
    function handleCalculateEnergyClick(){
        setIsCalculateEnergyModalVisible(true);
    }

    return (
        <>
        <Card className="h-100">
            <Card.Header>
                <div className='d-flex flex-row align-items-center' style={{ height: '36px' }}>
                    {/* <img src='https://placehold.it/32x32'/> */}
                    <Card.Title className='p-0 m-0'>
                        <TaskSlotCardStatus status={slot.Status} makerMode={true} /> {' '}
                        {!props.showResultsInHeader && <>{slot.Qty} x Slot request</>}
                        {props.showResultsInHeader && <><img className="item-icon me-2" src={`/item_images/${task.Recipe.Output.ItemId}.png`}/>{slot.Qty*task.Recipe.Output.Qty} x {task.Recipe.Output.Name}</>}
                    </Card.Title>
                    <div className='ms-auto'>from <b>{slot.TakerUser.Username}</b></div>
                </div>
            </Card.Header>
            <ListGroup className="list-group-flush">
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">Ingredients</ListGroup.Item>
                {task.Recipe.Input.map(q => {
                    return <ListGroup.Item><div><img className="item-icon me-2" src={`/item_images/${q.ItemId}.png`}/>{slot.Qty * q.Qty}x{q.Name}</div></ListGroup.Item>
                })}
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">Energy</ListGroup.Item>
                <ListGroup.Item>
                    <div className='d-flex flex-row align-items-center' style={{ height: '36px' }}>
                        <div><img className="item-icon me-2" src={EnergyIcon}/>{task.Recipe.EnergyUse * slot.Qty}</div>
                        {relatedToLoggedUser && task.Recipe.EnergyUse > 0 && <Button className="ms-auto" size="sm" variant="success" onClick={handleCalculateEnergyClick}>Calculate energy source</Button>}
                    </div>
                </ListGroup.Item>
                {task.RewardPool && <>
                    <ListGroup.Item variant="secondary" className="pt-1 pb-1">Reward</ListGroup.Item>
                    <ListGroup.Item><div><img className="item-icon me-2" src={`/item_images/${task.RewardPool.ItemId}.png`}/>{Math.floor(task.RewardPool.Qty * slot.Qty / task.Qty)}x{task.RewardPool.Name}</div></ListGroup.Item>
                </>}
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">Results</ListGroup.Item>
                <ListGroup.Item><div><img className="item-icon me-2" src={`/item_images/${task.Recipe.Output.ItemId}.png`}/>{task.Recipe.Output.Qty * slot.Qty}x{task.Recipe.Output.Name}</div></ListGroup.Item>
            </ListGroup>
            <Card.Footer className="h-100 d-flex align-items-end justify-content-end">
                <div>
                    {
                    statusesToAssign.map(status=><TaskSlotCardActionButton
                    statusToSet={status}
                    isDisabled={isActionLoading}
                    currentlyApplyingStatus={currentApplyingStatus}
                    onClick={applyStatus}
                    />)}
                    {statusesToAssign.length == 0 && <Button variant="secondary" style={{visibility:"hidden"}}>No actions</Button>}
                </div>
            </Card.Footer>
        </Card>
        {isCalculateEnergyModalVisible && <EnergyCalculationModal
        isVisible={isCalculateEnergyModalVisible}
        onClose={()=>setIsCalculateEnergyModalVisible(false)}
        requiredEnergy={task.Recipe.EnergyUse*slot.Qty}/>}
        </>
    )
}