
import EnergyIcon from "../img/hud_icon_energy.png";
import { ReactElement, useContext, useState } from "react";
import { Button, Col, Form, FormGroup, ListGroup, Modal, Row } from "react-bootstrap";
import DropdownWithSearch from "../DropdownWithSearch";
import CraftingRecipe from "../entity/CraftingRecipe";
import api from "../api";
import SpinnableText from "../components/SpinnableText";
import ItemInfo from "../entity/ItemInfo";

function TaskPreviewModal(props: { isVisible: boolean, onClose: () => void, recipe: CraftingRecipe, qty: number, rewardItem:ItemInfo|null, rewardQty:number }) {
    const [isClosing, setIsClosing] = useState(false);
    return (
        <Modal show={props.isVisible && !isClosing} onHide={() => setIsClosing(true)} onExited={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Task preview</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <ListGroup className="list-group-flush">
                    <ListGroup.Item variant="secondary">Ingredients</ListGroup.Item>
                    {props.recipe.Input.map(q => <ListGroup.Item key={q.ItemId}><img className="item-icon me-2" src={`/item_images/${q.ItemId}.png`}/>{props.qty * q.Qty}x{q.Name}</ListGroup.Item>)}
                    <ListGroup.Item><img className="item-icon me-2" src={EnergyIcon}/>{props.qty * props.recipe.EnergyUse}xEnergy</ListGroup.Item>
                    {props.rewardItem && <>
                        <ListGroup.Item variant="secondary">Reward pool</ListGroup.Item>
                        <ListGroup.Item><img className="item-icon me-2" src={`/item_images/${props.rewardItem?.Id}.png`}/>{props.rewardQty}x{props.rewardItem.Name}</ListGroup.Item>
                    </>}
                    <ListGroup.Item variant="secondary">Results</ListGroup.Item>
                    <ListGroup.Item><img className="item-icon me-2" src={`/item_images/${props.recipe.Output.ItemId}.png`}/>{props.qty * props.recipe.Output.Qty}x{props.recipe.Output.Name}</ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setIsClosing(true)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>);
}

export default function CreateTaskModal(props: { isVisible: boolean, onClose: () => void, onCreated: () => void }) {
    const [isClosing, setIsClosing] = useState(false);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [isRequestLoading, setIsRequestLoading] = useState(false);

    const recipesReq = api.useGetResult<CraftingRecipe[]>('/resources/recipes');
    const recipeSelectPlaceholderText = recipesReq.isLoading ? "Loading recipes" : (recipesReq.error ? recipesReq.error + '' : 'Select recipe');
    const recipesItems: any[] = recipesReq.isSuccess ? recipesReq.data ?? [] : [];

    const itemsReq = api.useGetResult<ItemInfo[]>('/resources/items');
    const rewardSelectPlaceholderText = itemsReq.isLoading ? "Loading items" : (itemsReq.error ? itemsReq.error + '' : 'Select reward');
    const rewardItems: any[] = itemsReq.isSuccess ? itemsReq.data ?? [] : [];

    //form input values
    const [recipe, setRecipe] = useState<CraftingRecipe | null>(null);
    const [qty, setQty] = useState(1);
    const [useQtyLimits, setUseQtyLimits] = useState(false);
    const [minQty, setMinQty] = useState(1);
    const [maxQty, setMaxQty] = useState(1000000);
    const [useReward, setUseReward] = useState(false);
    const [rewardItem, setRewardItem] = useState<ItemInfo | null>(null);
    const [rewardQty, setRewardQty] = useState(1);

    function handleCreateTaskClick() {
        setIsRequestLoading(true);
        api.api.performApiRequest('/taskboard/tasks/create', 'POST', {
            RecipeId: recipe?.Id,
            Qty: qty,
            MinQtyPerRequest: useQtyLimits ? minQty : Math.max(1, Math.round(qty / 4)),//use 25% by default
            MaxQtyPerRequest: maxQty,
            RewardItemId: useReward?rewardItem?.Id:null,
            RewardItemQty: rewardQty
        })
            .then(res => {
                alert('success');
                setIsClosing(false);
                //close window
                handleClosing();
                props.onCreated();
            })
            .catch(err => {
                alert(err);
                setIsRequestLoading(false);
            })
    }
    function handleRecipeChange(key: string) {
        const selectedRecipe = recipesReq.data!.find(q => q.Id === key)!;
        setRecipe(selectedRecipe);
    }
    function handleRewardChange(key: string) {
        const selectedItem = itemsReq.data!.find(q => q.Id == key)!;
        setRewardItem(selectedItem);
    }
    function handleClosing() {
        if (isRequestLoading) {
            return;
        }
        setIsClosing(true);
    }
    return (<>
        <Modal show={props.isVisible && !isClosing} onHide={handleClosing} onExited={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create new task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => e.preventDefault()}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Select recipe</Form.Label>
                        <DropdownWithSearch
                            items={recipesItems}
                            itemRender={(r: CraftingRecipe) => <><img className="item-icon me-2" src={`/item_images/${r.Output.ItemId}.png`}/>{r.Output.Qty + " x " + r.Output.Name}</>}
                            itemToKey={q => q.Id}
                            itemFilter={(recipe: CraftingRecipe, filter: string) => {
                                return recipe.Output.Name.toLowerCase().includes(filter.toLowerCase());
                            }}
                            placeholderText={recipeSelectPlaceholderText}
                            disabled={!recipesReq.isSuccess}
                            onSelect={handleRecipeChange}
                            toggleVariant={recipe?'secondary':'primary'}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Row className="align-items-center">
                            <Col xs={6}>
                                <Form.Label>Qty</Form.Label>

                            </Col>
                            <Col xs={6}>
                                <Form.Check disabled={!recipe} type="switch" label="Use qty limits" onChange={e => setUseQtyLimits(e.currentTarget.checked)} />
                            </Col>
                        </Row>
                        <Form.Control disabled={!recipe} as="input" type="number" defaultValue={1} min={1} onChange={(e) => setQty(+e.currentTarget.value)} />

                    </Form.Group>
                    {useQtyLimits &&
                        <Form.Group className="mb-3">
                            <Row>
                                <Col xs={6}>
                                    <Form.Label>Min qty / request</Form.Label>
                                    <Form.Control disabled={!recipe} as="input" type="number" defaultValue={1} min={1} onChange={(e) => setMinQty(+e.currentTarget.value)} />
                                </Col>
                                <Col xs={6}>
                                    <Form.Label>Max qty / request</Form.Label>
                                    <Form.Control disabled={!recipe} as="input" type="number" defaultValue={1000000} min={1} onChange={(e) => setMaxQty(+e.currentTarget.value)} />
                                </Col>
                            </Row>
                        </Form.Group>}
                    <FormGroup className="mb-3">
                        <Form.Check type="switch" label="Use reward" onChange={e => setUseReward(e.currentTarget.checked)} />
                    </FormGroup>
                    {useReward && <>
                        <FormGroup className="mb-3">
                            <Form.Label>Select reward</Form.Label>
                            <DropdownWithSearch
                                items={rewardItems}
                                itemRender={(i: ItemInfo) => <><img className="item-icon me-2" src={`/item_images/${i.Id}.png`}/>{i.Name}</>}
                                itemToKey={q => q.Id}
                                itemFilter={(i: ItemInfo, f: string) => {
                                    return i.Name.toLowerCase().includes(f.toLowerCase());
                                }}
                                placeholderText={rewardSelectPlaceholderText}
                                disabled={!itemsReq.isSuccess}
                                onSelect={handleRewardChange}
                                toggleVariant={rewardItem?'secondary':'primary'}
                            />
                        </FormGroup>
                        <Form.Group className="mb-3">
                            <Form.Label>Reward pool size</Form.Label>
                            <Form.Control disabled={!rewardItem} as="input" type="number" defaultValue={1} min={1} onChange={(e) => setRewardQty(+e.currentTarget.value)} />
                        </Form.Group>
                    </>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isRequestLoading} variant="secondary" onClick={handleClosing}>
                    Close
                </Button>
                <Button disabled={!recipe || useReward && !rewardItem || isRequestLoading} variant="secondary" onClick={() => setIsPreviewVisible(true)}>
                    Preview
                </Button>
                <Button disabled={!recipe || useReward && !rewardItem || isRequestLoading} variant="primary" onClick={handleCreateTaskClick}>
                    <SpinnableText text="Create task" isSpinning={isRequestLoading} />
                </Button>
            </Modal.Footer>
        </Modal>
        {isPreviewVisible && <TaskPreviewModal
            isVisible={isPreviewVisible}
            recipe={recipe!}
            qty={qty}
            onClose={() => setIsPreviewVisible(false)} 
            rewardItem={rewardItem}
            rewardQty={rewardQty}/>
            }
    </>
    );
}