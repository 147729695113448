import { useState } from "react"
import { Container, Row, Col, Button, OverlayTrigger, Popover } from "react-bootstrap";
import SpinnableText from "../components/SpinnableText";
import api from "../api";
import { SiweMessage } from "siwe";
import { ethers } from "ethers";
import LoginWithPasswordModal from "./LoginWithPasswordModal";

// enum LoginMode{
//     Siwe,
//     LoginPassword,
//     Transaction
// }
declare global {
    interface Window {
        ronin?: any
    }
}
function PerformSiweLogin(): Promise<unknown> {
    const provider = window.ronin.provider;
    const accountsPromise: Promise<any> = provider.request({ method: "eth_requestAccounts" });
    return accountsPromise
        .then(accounts => {
            if (!accounts) {
                throw new Error("Connection cancelled");
            }
            const currentAccount = accounts[0];
            const wellFormedAddress = ethers.getAddress(currentAccount);
            return wellFormedAddress;
        })
        .then(wellFormedAddress => {
            return api.api.requestSiweMessage(wellFormedAddress)
                .then(msgArg => {
                    var msg = new SiweMessage(msgArg);
                    const sigPromise: Promise<string> = provider.request({ method: "personal_sign", params: [msg.toMessage(), wellFormedAddress] })
                    return sigPromise.then(sig => { return { sig: sig, msg: msg } });

                }).then(sigAndMsg => {
                    return api.api.submitSiweSignature(sigAndMsg.msg.nonce, sigAndMsg.sig);
                });
        });
}
function LoginHelp() {
    return (<Popover id="popover-basic">
        <Popover.Header as="h3">How to sign in?</Popover.Header>
        <Popover.Body>
            <b>Ronin wallet extension</b><br />
            Connect your wallet and sign one-time login message<br />
            <a href="https://docs.guildconsole.xyz/accounts/sign-in-with-ronin-chrome" target="_blank">read in docs</a><br/>
            <b>Ronin mobile wallet</b><br />
            Open wallet app, navigate to guildconsole.xyz on dapps tab, connect and sign the message <br />
            After that you can set up password and login in any mobile browser<br/>
            <a href="https://docs.guildconsole.xyz/accounts/sign-in-with-ronin-mobile" target="_blank">read in docs</a><br/>
            <b>Ask admin to set password</b><br />
            Ask admin of your guild to set initiall password for you. Use that password to login and change it later.<br/>
            <a href="https://docs.guildconsole.xyz/accounts/sign-in-with-password" target="_blank">read in docs</a><br/>
        </Popover.Body>
    </Popover>);
}
export default function LoginPage() {
    //const [loginMode, setLoginMode] = useState<LoginMode|null>(null);
    //const [siweLoginVisible, setSiweLoginVisible] = useState(false);
    const [isSignWithRoninInProgress, setIsSignWithRoninInProgress] = useState(false);
    const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
    const [loginWithPasswordVisible, setLoginWithPasswordVisible] = useState(false);
    function handleSiweLoginClick() {
        if (window.ronin == undefined) {
            alert("No ronin wallet found");
            return;
        }
        setIsSignWithRoninInProgress(true);
        setAreButtonsDisabled(true);
        PerformSiweLogin()
            .catch(err => {
                alert(err);
                setAreButtonsDisabled(false);
                setIsSignWithRoninInProgress(false);
            });
    }
    function handleLoginWithPasswordClick() {
        setLoginWithPasswordVisible(true);
    }
    return <>
        <div className="vertical-center">
            <Container>
                <Row className="justify-content-center mb-3">
                    <Col xs="12" md="6" lg="4" className="d-flex justify-content-center">
                        <h1>GuildConsole</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                    <Col xs="12" md="6" lg="4" className="d-flex justify-content-center">
                        <Button disabled={areButtonsDisabled} onClick={handleSiweLoginClick}><SpinnableText text="Sign in with Ronin" isSpinning={isSignWithRoninInProgress} /></Button>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                    <Col xs="12" md="6" lg="4" className="d-flex justify-content-center">
                        <Button disabled={areButtonsDisabled} onClick={handleLoginWithPasswordClick}>Sign in with password</Button>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xs="12" md="6" lg="4" className="d-flex justify-content-center">
                        <OverlayTrigger
                            trigger="click"
                            placement="top"
                            overlay={LoginHelp()}
                        >
                            <button className="icon-button">
                                <i className="bi bi-question-circle h4"></i>
                            </button>
                        </OverlayTrigger>
                    </Col>
                </Row>

            </Container>
        </div>
        <LoginWithPasswordModal
            isVisible={loginWithPasswordVisible}
            onClose={() => setLoginWithPasswordVisible(false)}
            onSuccess={() => setLoginWithPasswordVisible(false)}
        />
    </>
}