import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import api from './api';
import { Button } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import UsersPage from './UsersPage/UsersPage';
import AppHeader from './Header/AppHeader';
import UserInfo from './entity/UserInfo';
import { LoggedUserInfoContext } from './LoggedUserInfoContext';
import TaskPage from './Taskboard/TaskPage';
import TaskboardPage from './Taskboard/TaskboardPage';
import TaskSlotsPage from './Taskboard/TaskSlotsPage';
import TakerTaskSlotsPage from './Taskboard/TakerTaskSlotsPage';
import MakerTaskSlotsPage from './Taskboard/MakerTaskSlotsPage';
import LoginPage from './Login/LoginPage';
import PlannerPage from './Planner/PlannerPage';
import MyReservationsPage from './Planner/MyReservationsPage';


function App() {
  api.useApi();
  if (api.api.authToken == null) {
    //not logged in
    //auth form always
    return <LoginPage />
  }

  function RenderApp() {
    const loggedUserInfoContext = api.useGetResult<UserInfo>("/users/current");
    var currentTheme = localStorage.getItem("theme")??"light";
    document.documentElement.setAttribute("data-bs-theme",currentTheme);
    return <>
     <LoggedUserInfoContext.Provider value={loggedUserInfoContext}>
      {renderHeader()}
      <main>
        <Routes>
          <Route path="/login" Component={LoginPage} />
          <Route path='/taskboard' Component={TaskboardPage} />
          <Route path='/slots-as-taker' Component={TakerTaskSlotsPage}/>
          <Route path='/slots-as-maker' Component={MakerTaskSlotsPage}/>
          <Route path='/tasks/:taskId' Component={TaskPage} />
          <Route path='/planner' Component={PlannerPage}/>
          <Route path='/my-reservations' Component={MyReservationsPage}/>
          {/* <Route path='/my-tasks' Component={MyTasksPage}/> */}
          <Route path='/users' Component={UsersPage} />
          <Route path='/' Component={Home}/>
        </Routes>
      </main>
      </LoggedUserInfoContext.Provider>
    </>
  }
  function renderHeader() {
    if (api.api.authToken == null) {
      return <></>
    }
    return (
      <header>
        <AppHeader />
      </header>)
  }
  return <RenderApp/>
}

export default App;
