import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import ResourceReservation from "../entity/ResourceReservation";
import ResourceReservationWithResource from "../entity/ResourceReservationWithResource";
import { DateTime } from "luxon";
import { useState } from "react";
import SpinnableText from "../components/SpinnableText";
import api from "../api";

class ReservationWithTimestamps{
    public readonly reservation :ResourceReservationWithResource;
    public readonly from:DateTime;
    public readonly to:DateTime;
    public constructor(res:ResourceReservationWithResource){
        this.reservation = res;
        this.from = DateTime.fromISO(res.Reservation.From);
        this.to = DateTime.fromISO(res.Reservation.To);
    }
}

function ReservationCard(props:{res:ReservationWithTimestamps, onChanged: ()=>void}){

    const [isLoading, setIsLoading] = useState(false);
    function handleDeleteClick(){
        setIsLoading(true);
        api.api.performApiRequest(`/planner/reservations/${props.res.reservation.Reservation.Id}`, 'DELETE')
        .then(res=>{
            setIsLoading(false);
            props.onChanged();
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        });
    }
    return <Card>
        <Card.Header>
            Reservation
        </Card.Header>
        <ListGroup className="list-group-flush">
            <ListGroup.Item>Land: <b>#{props.res.reservation.Resource.LandNo}</b></ListGroup.Item>
            <ListGroup.Item>Resource: <b>{props.res.reservation.Resource.Name}</b></ListGroup.Item>
            <ListGroup.Item>From: <b>{props.res.from.toLocaleString(DateTime.DATETIME_FULL)}</b></ListGroup.Item>
            <ListGroup.Item>To: <b>{props.res.to.toLocaleString(DateTime.DATETIME_FULL)}</b></ListGroup.Item>
        </ListGroup>
        <Card.Footer>
            <Button variant="danger" disabled={isLoading} onClick={handleDeleteClick}>
                <SpinnableText text="Delete reservation" isSpinning={isLoading}/>
            </Button>
        </Card.Footer>
    </Card>
}

export default function MyReservationsPage() {
    var reservationsReq = api.useGetResult<ResourceReservationWithResource[]>('/planner/my-reservations');
    var reservationsParsed = reservationsReq.data?.map(q=>new ReservationWithTimestamps(q))??[];

    return <Container>
        <Row className="mt-3" style={{ marginBottom: -10 }}>
            <h2>My reservations</h2>
        </Row>
        {reservationsReq.isLoading && <Row className="mt-3"><h4>Loading data...</h4></Row>}
        {reservationsReq.error && <Row className="mt-3"><h4>Error: {reservationsReq.error + ''}</h4></Row>}
        {reservationsReq.isSuccess && reservationsReq.data!.length == 0 && <Row className="mt-3"><h4>Lonely here. Try booking on planner page.</h4></Row>}
        {reservationsReq.isSuccess && reservationsReq.data!.length > 0 && <Row xs={1} lg={2}>
            {reservationsParsed.map(q => (
                <Col className="mt-3">
                    <ReservationCard
                        key={q.reservation.Reservation.Id}
                        res={q}
                        onChanged={reservationsReq.reload}
                    />
                </Col>
            ))}
        </Row>}
    </Container>
}