import { FormEvent, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import api, { ApiError } from "../api";
import { Link, useNavigate } from "react-router-dom";
import SpinnableText from './../components/SpinnableText';

export default function LoginWithPasswordModal(props: { isVisible: boolean, onClose: () => void, onSuccess: () => void }) {
    const navigate = useNavigate();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    function onSubmit(e: FormEvent) {
        e.preventDefault();
        //reset errors
        setIsLoading(true)
        api.api.loginWithPassword(login, password)
            .then((e) => {
                props.onSuccess();
            })
            .catch(err => {
                setIsLoading(false);
                if (err instanceof ApiError) {
                    //we got some rejection err
                    alert(err);
                } else {
                    console.log(err);
                    alert(err);
                }
            });
    }
    return <>
        <Modal show={props.isVisible} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Set password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit} style={{ margin: "auto" }}>
                    <Form.Group className="mb-3">
                        <Form.Label>Ronin address</Form.Label>
                        <Form.Control placeholder="0x0000000000000000000000000000000000000000" onChange={(q) => setLogin(q.currentTarget.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="password" onChange={(q) => setPassword(q.currentTarget.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onSubmit} disabled={isLoading}><SpinnableText text="Sign in" isSpinning={isLoading}/></Button>
            </Modal.Footer>
        </Modal>
    </>
}