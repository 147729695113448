import { Button, Dropdown, ListGroup, Spinner } from "react-bootstrap";
import UserInfo from "../entity/UserInfo";
import { useContext, useState } from "react";
import api from "../api";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import UserGuildRole from "../entity/UserGuildRole";

export default function UserRow(props: { userInfo: UserInfo, onChangePasswordClick:(userId:string)=>void, onChanged: (reloadedCallback:()=>void) => void }) {
    const myInfo = useContext(LoggedUserInfoContext);
    const u = props.userInfo;
    const roles = [UserGuildRole.Admin, UserGuildRole.TaskMaker, UserGuildRole.Member];
    const [isChangeRoleLoading, setIsChangeRoleLoading] = useState(false);
    function handleRoleChange(rolestr: string | null) {
        if (rolestr == null) {
            return;
        }
        
        const role = UserGuildRole[rolestr as keyof typeof UserGuildRole];
        // if(u.Id == myInfo.data?.Id && role == UserGuildRole.Admin){
        //     alert("you cannot revoke your admin role!");
        //     return;
        // }
        const path = u.Roles.includes(role)?`/users/user/${u.Id}/revoke-role`:`/users/user/${u.Id}/assign-role`;
        setIsChangeRoleLoading(true);
        api.api.performApiRequest(path, "PATCH", { Id: u.Id, Role: role })
            .then(res => {
                if(u.Id == myInfo.data?.Id){
                    //changed my role, need to refresh myinfo
                    myInfo.reload();
                }
                props.onChanged(()=>{
                    setIsChangeRoleLoading(false);
                });
            })
            .catch(err => {
                alert(err);
                setIsChangeRoleLoading(false);
            })
    }
    return (
        <ListGroup.Item key={u.Id}>
            <div className='d-flex flex-row align-items-center' style={{ height: '36px' }}>
                <div>{u.Username}</div>
                <div className='ms-auto d-flex flex-row'>
                    {!props.userInfo.HasPassword && <Button className="me-2" onClick={()=>props.onChangePasswordClick(props.userInfo.Id)}>Set password</Button>}
                    <Dropdown className="me-1" onSelect={handleRoleChange}>
                        <Dropdown.Toggle disabled={isChangeRoleLoading}>
                            {isChangeRoleLoading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />}
                            {!isChangeRoleLoading && <>{'Roles'}</>}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {roles.map((r) => {
                                const roleStr = UserGuildRole[r];
                                return <Dropdown.Item className="role-dropdown-item" active={u.Roles.includes(r)} key={roleStr} eventKey={roleStr}>{roleStr}</Dropdown.Item>
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </ListGroup.Item>
    );
}