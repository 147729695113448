import { useState } from "react";
import ResourceReservation from "../entity/ResourceReservation";
import { Modal, Button } from "react-bootstrap";
import SpinnableText from "../components/SpinnableText";
import SharedResource from "../entity/SharedResource";
import { DateTime } from "luxon";
import api from "../api";

export default function EditReservationModal(props: {isVisible: boolean, onClose: ()=>void, onChanged:()=>void, reservation: ResourceReservation, resource: SharedResource}){
    const [isClosing, setIsClosing] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const parsedStart = DateTime.fromISO(props.reservation.From);
    const parsedEnd = DateTime.fromISO(props.reservation.To);
    function handleDeleteClick(){
        setIsLoading(true);
        api.api.performApiRequest(`/planner/reservations/${props.reservation.Id}`, 'DELETE')
        .then(res=>{
            setIsSuccess(true);
            setIsClosing(true);
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        })
    }
    return <Modal show={props.isVisible && !isClosing} onHide={() => setIsClosing(true)} onExited={isSuccess?props.onChanged:props.onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Edit reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>Booking <b>{props.resource.Name}</b> on land <b>#{props.resource.LandNo}</b></div>
            <div>Player: <b>{props.reservation.User.Username}</b></div>
            <div>From:<b>{parsedStart.toLocaleString(DateTime.DATETIME_FULL)}</b></div>
            <div>To:<b>{parsedEnd.toLocaleString(DateTime.DATETIME_FULL)}</b></div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" disabled={isLoading} onClick={handleDeleteClick}>
                <SpinnableText text="Delete reservation" isSpinning={isLoading}/>
            </Button>
        </Modal.Footer>
    </Modal>
    
}