import { Spinner } from "react-bootstrap"

interface SpinnableTextProps{
    text:string,
    isSpinning:boolean
}
export default function SpinnableText(props:SpinnableTextProps){
    if(props.isSpinning){
        return <>
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
        <div style={{ opacity: 0, lineHeight: 0, display:"inline !important"}}>{props.text}</div>
        </>
    }
    return <div>{props.text}</div>
}