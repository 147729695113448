import { useContext, useState } from "react";
import api from "../api"
import ResourceReservation from "../entity/ResourceReservation"
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import SharedResource from "../entity/SharedResource";
import { DateTime, Interval } from "luxon";
import ResourceTimeline from "./ResourceTimeline";
import BookingConfirmationModal from "./BookingConfirmationModal";
import EditReservationModal from "./EditReservationModal";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import UserGuildRole from "../entity/UserGuildRole";
function BookingModalHelp() {
    return (<Popover id="popover-basic">
        <Popover.Header as="h3">Timeline</Popover.Header>
        <Popover.Body>
            <b>What is a timeline?</b><br />
            Timeline is displayed as multiple rows (hours) and slots (10 minutes each). For example slot with number 20 represents duration from 20 to 30 minutes.<br />
            Your reservations are marked with blue, others - gray<br />
            <b>How to book?</b><br />
            Please select an interval by clicking on two buttons (which will represent start and end of the period). Selected interval will be marked green.<br />
            <a href="https://docs.guildconsole.xyz/planner/how-to-book-resource" target="_blank">more info in docs</a>
        </Popover.Body>
    </Popover>);
}
export default function BookingModal(props: { resource: SharedResource, isVisible: boolean, onClose: () => void , onCloseWithChanges:()=>void}) {
    const intervalSecs = 60 * 10;
    const userReq = useContext(LoggedUserInfoContext);
    const [isClosing, setIsClosing] = useState(false);
    const [selectedInterval, setSelectedInterval] = useState<Interval | null>(null);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [editedReservation, setEditedReservation] = useState<ResourceReservation | null>(null);
    const [changesWereMade, setChangesWereMade] = useState(false);
    const reservationsReq = api.useGetResult<ResourceReservation[]>(`/planner/shared-resource/${props.resource.Id}/reservations`);

    function handleIntervalSelected(from: DateTime | null, to: DateTime | null) {
        if (!from) {
            setSelectedInterval(null);
            return;
        }
        if (!to) {
            setSelectedInterval(Interval.fromDateTimes(from, from.plus({ seconds: intervalSecs })));
        } else {
            setSelectedInterval(Interval.fromDateTimes(from, to.plus({ seconds: intervalSecs })));
        }
    }
    function handleReservationClicked(reservation: ResourceReservation) {
        if (reservation.User.Id == userReq.data?.Id || userReq.data?.Roles.includes(UserGuildRole.Admin)) {
            setEditedReservation(reservation);
        }
    }
    return <>
        <Modal scrollable show={props.isVisible && !isClosing} onHide={() => setIsClosing(true)} onExited={changesWereMade?props.onCloseWithChanges:props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Booking</Modal.Title>
                {/* <div className="d-flex align-items-stretch ms-auto" style={{height:"100%"}}>
                    <button className="icon-button">
                        <i className="bi bi-question-circle h5"></i>
                    </button>
                    
                    <button
                        className='btn-close'
                        aria-label="close"
                        onClick={()=>setIsClosing(true)}
                        style={{ marginTop: -2 }}
                    >
                    </button>
                </div> */}
            </Modal.Header>
            <Modal.Body>
                {reservationsReq.isLoading && <>Loading</>}
                {/* {reservationsReq.isSuccess && <>
                {reservationsReq.data?.map(q=><div>From {q.From + ''} to {q.To + ''}</div>)}
            </>} */}
                {reservationsReq.isSuccess && <>
                    <ResourceTimeline
                        timestamp={reservationsReq.timestamp!}
                        reservations={reservationsReq.data!}
                        intervalSecs={intervalSecs}
                        onIntervalSelected={handleIntervalSelected}
                        onReservationClicked={handleReservationClicked}
                    />
                </>}
            </Modal.Body>
            <Modal.Footer>
                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={BookingModalHelp()}
                >
                    <button className="icon-button">
                        <i className="bi bi-question-circle h4"></i>
                    </button>
                </OverlayTrigger>
                <Button variant="primary" disabled={!reservationsReq.isSuccess || !selectedInterval} onClick={() => setConfirmModalVisible(true)}>Book</Button>
            </Modal.Footer>
        </Modal>
        {isConfirmModalVisible && <BookingConfirmationModal
            isVisible={isConfirmModalVisible}
            selectedInterval={selectedInterval!}
            resource={props.resource}
            onClose={() => setConfirmModalVisible(false)}
            onSuccess={() => {
                setConfirmModalVisible(false);
                setSelectedInterval(null);
                setChangesWereMade(true);
                reservationsReq.reload();
            }}
        />}
        {editedReservation && <EditReservationModal
            isVisible={editedReservation != null}
            reservation={editedReservation!}
            resource={props.resource}
            onClose={() => setEditedReservation(null)}
            onChanged={() => {
                setEditedReservation(null);
                setChangesWereMade(true);
                reservationsReq.reload();
            }}
        />}
    </>
}