import { sha256 } from "js-sha256";
import { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import api from "../api";
import SpinnableText from "../components/SpinnableText";

export default function SetPasswordModal(props: { isVisible: boolean, userId:string, onClose: () => void, onSuccess: () => void }) {
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    function onSubmit() {
        if(password.length < 6){
            alert("Password must be at least 6 symbols");
            return;
        }
        setIsLoading(true);
        var pHash = sha256(password);
        /*.then((pHash)=>{
            return */api.api.performApiRequest(`/users/user/${props.userId}/set-password`, "PATCH", {
            PasswordHash: pHash
        })
            //})
            .then((res) => {
                //succesfully created
                setIsLoading(false);
                props.onSuccess();
            })
            .catch(err => {
                alert(err);
                setIsLoading(false);
            })
    }
    return <Modal show={props.isVisible} onHide={props.onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Set password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={e => e.preventDefault()}>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="password" onChange={(q) => setPassword(q.currentTarget.value)} />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" disabled={isLoading} onClick={onSubmit}>
                <SpinnableText isSpinning={isLoading} text="Submit" />
            </Button>
        </Modal.Footer>
    </Modal>
}