import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import api from "../api";
import { GuildInfo } from "../entity/GuildInfo";
import DropdownWithSearch from "../DropdownWithSearch";
import SpinnableText from "../components/SpinnableText";

export default function CreateSharedResourceModal(props: { isVisible: boolean, onClose: () => void, onSuccess: () => void }) {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const guildInfoReq = api.useGetResult<GuildInfo>('/guild/my-guild');
    const [landNo, setLandNo] = useState<number | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [isInHouse, setIsInHouse] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    function handleLandChange(key: string) {
        setLandNo(+key);
    }
    function handleSubmit() {
        setIsLoading(true);
        api.api.performApiRequest('/planner/shared-resource/create', 'POST', {
            LandNo:landNo,
            IsInHouse:isInHouse,
            Name:name
        })
        .then(res=>{
            setIsSuccess(true);
            setIsClosing(true);
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        })
    }
    const guildLandsItems: any[] = guildInfoReq.isSuccess ? guildInfoReq.data?.Lands ?? [] : [];
    return (<Modal show={props.isVisible && !isClosing} onHide={() => setIsClosing(true)} onExited={()=>isSuccess?props.onSuccess():props.onClose()}>
        <Modal.Header closeButton>
            Create shared resource
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={e => e.preventDefault()}>
                <Form.Group className="mb-3">
                    <Form.Label>Land</Form.Label>
                    <DropdownWithSearch
                        items={guildLandsItems}
                        itemRender={(r) => <>#{r}</>}
                        itemToKey={(q: any) => q + ' '}
                        itemFilter={(landNo: number, filter: string) => {
                            return (landNo + '').includes(filter);
                        }}
                        placeholderText={'#LAND'}
                        disabled={!guildInfoReq.isSuccess || isLoading}
                        onSelect={handleLandChange}
                        toggleVariant={landNo ? 'secondary' : 'primary'}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control disabled={isLoading} placeholder="farming area 1" onChange={(q) => setName(q.currentTarget.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Check type="switch" disabled={isLoading} label="Is in house" onChange={e => setIsInHouse(e.currentTarget.checked)} />
                    </Form.Group>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" disabled={isLoading || !landNo || !name} onClick={handleSubmit}>
                <SpinnableText isSpinning={isLoading} text="Submit" />
            </Button>
        </Modal.Footer>
    </Modal>)
}