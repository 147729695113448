enum TaskSlotStatus {
    Pending,
    Accepted,
    InProgress,
    Done,
    Closed,
    Rejected,
    Cancelled
}
export const resourceConsumedStatuses = [TaskSlotStatus.Accepted, TaskSlotStatus.InProgress, TaskSlotStatus.Done, TaskSlotStatus.Closed];
export const resourceProducedStatuses = [TaskSlotStatus.Closed];
export const assignableStatusMaker = new Map<TaskSlotStatus, TaskSlotStatus[]>([
    [TaskSlotStatus.Pending, [TaskSlotStatus.Accepted, TaskSlotStatus.Rejected]],
    [TaskSlotStatus.Accepted, [TaskSlotStatus.Cancelled]],
    [TaskSlotStatus.InProgress, []],
    [TaskSlotStatus.Done, [TaskSlotStatus.Closed]],
    [TaskSlotStatus.Closed, []],
    [TaskSlotStatus.Rejected, []],
    [TaskSlotStatus.Cancelled, []],
]);
export const assignableStatusTaker = new Map<TaskSlotStatus, TaskSlotStatus[]>([
    [TaskSlotStatus.Pending, [TaskSlotStatus.Cancelled]],
    [TaskSlotStatus.Accepted, [TaskSlotStatus.InProgress]],
    [TaskSlotStatus.InProgress, [TaskSlotStatus.Done]],
    [TaskSlotStatus.Done, []],
    [TaskSlotStatus.Closed, []],
    [TaskSlotStatus.Rejected, []],
    [TaskSlotStatus.Cancelled, []],
]);
export default TaskSlotStatus;