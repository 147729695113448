import { Navbar, Container, Nav, NavDropdown, Button, Row, Col } from "react-bootstrap";
import api from "../api";
import HeaderUserButton from "./HeaderUserButton";
import { useContext, useState } from "react";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import { Link } from "react-router-dom";
import UserGuildRole from "../entity/UserGuildRole";

export default function AppHeader() {
    const userReq = useContext(LoggedUserInfoContext);
    let [currentTheme, setCurrentTheme] = useState(localStorage.getItem("theme")??"light");
    function handleChangeThemeButtonClick(){
        let newTheme = "light";
        if(currentTheme == "light"){
            newTheme = "dark";
        }
        localStorage.setItem("theme",newTheme)
        document.documentElement.setAttribute("data-bs-theme",newTheme);
        setCurrentTheme(newTheme);
    }
    return <>
        <Navbar expand="lg" className="bg-body-tertiary mb-3">
            <Container>
                <Navbar.Brand href="/">Guild Console</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav ">
                    <Nav className="me-auto">
                        {/* <Nav.Link as={Link} to="/">Home</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/taskboard">Taskboard</Nav.Link>
                        <Nav.Link as={Link} to="/slots-as-taker">My work requests</Nav.Link>
                        {!userReq.isLoading && userReq.data?.Roles.includes(UserGuildRole.TaskMaker) && <Nav.Link as={Link} to="/slots-as-maker">Requests to my tasks</Nav.Link>} */}
                        <Nav.Link as={Link} to="/planner">Planner</Nav.Link>
                        <Nav.Link as={Link} to="/my-reservations">My reservations</Nav.Link>
                        {!userReq.isLoading && userReq.data?.Roles.includes(UserGuildRole.Admin) && <Nav.Link as={Link} to="/users">Users</Nav.Link>}
                        
                        
                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">
                                Separated link
                            </NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                    <Nav>
                        <Button onClick={handleChangeThemeButtonClick} variant={currentTheme}>
                            {currentTheme=='light' && <i className="bi bi-sun"/>}
                            {currentTheme=='dark' && <i className="bi bi-moon"/>}
                            </Button>
                        <HeaderUserButton/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
}