import { Button, Card, ListGroup } from "react-bootstrap";
import { ReactElement } from "react";
import CraftingTaskWithStats from "../entity/CraftingTaskWithStats";
import { useNavigate } from "react-router-dom";
import { stat } from "fs";
interface TaskCardProps{
    task:CraftingTaskWithStats
}
export default function TaskCard(props:TaskCardProps) {
    const navigate = useNavigate();
    const taskAndStats = props.task;
    const task = taskAndStats.Task;
    const stats = taskAndStats.Stats;
    return (
        <Card className='task-card'>
            <Card.Header>
                <div className='d-flex flex-row align-items-center' style={{ height: '36px' }}>
                    <Card.Title className='p-0 m-0'>
                        <img className="item-icon me-2" src={`/item_images/${task.Recipe.Output.ItemId}.png`}/>
                        {task.Qty}x<b>{task.Recipe.Output.Qty>1 && <>{task.Recipe.Output.Qty}x</>}{task.Recipe.Output.Name}</b></Card.Title>
                    <div className='ms-auto'>by <b>{task.MakerUser.Username}</b></div>
                </div>
            </Card.Header>
            <ListGroup className="list-group-flush">
                {/* <ListGroup.Item variant='secondary' className="pt-1 pb-1">Ingredients</ListGroup.Item>
                {task.Recipe.Input.map(q=>{
                    return <ListGroup.Item><div className="ps-2">{task.Qty*q.Qty}x{q.Name}</div></ListGroup.Item>
                })}
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">Energy</ListGroup.Item>
                <ListGroup.Item><div className="ps-2">{task.Recipe.EnergyUse * task.Qty}</div></ListGroup.Item>
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">Results</ListGroup.Item>
                <ListGroup.Item><div className="ps-2">{task.Recipe.Output.Qty*task.Qty}x{task.Recipe.Output.Name}</div></ListGroup.Item> */}

                {/* <ListGroup.Item variant='secondary'>Player</ListGroup.Item>
              <ListGroup.Item>poki66</ListGroup.Item> */}
              <ListGroup.Item>{stats.CompletedQty} completed</ListGroup.Item>
              <ListGroup.Item>{stats.InProgressQty} in progress</ListGroup.Item>
              <ListGroup.Item>{stats.OpenQty} open</ListGroup.Item>
              {task.RewardPool && <>
                <ListGroup.Item><div><img className="item-icon-24 me-2" src={`/item_images/${task.RewardPool.ItemId}.png`}/><b>{stats.RewardsLeft}x{task.RewardPool.Name}</b> rewards left</div></ListGroup.Item>              
              </>}
              {!task.RewardPool && <>
                <ListGroup.Item>no reward</ListGroup.Item>

              </>}
            </ListGroup>
            <Card.Footer>
                <div className="float-end">
                    <Button onClick={()=>navigate(`/tasks/${task.Id}`)}>Details</Button>
                </div>
            </Card.Footer>
        </Card>);
}