import { Badge, Button, Card, Container, ListGroup, Stack } from "react-bootstrap";
import api from "../api";
import GuildLandWithResources from "../entity/GuildLandWithResources";
import SharedResource from "../entity/SharedResource";
import { useContext, useState } from "react";
import CreateSharedResourceModal from "./CreateSharedResourceModal";
import EditSharedResourceModal from "./EditSharedResourceModal";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import UserGuildRole from "../entity/UserGuildRole";
import BookingModal from "./BookingModal";
import SharedResourceWithLabels from "../entity/SharedResourceWithLabels";
import SharedResourceLabel from "../entity/SharedResourceLabel";
import { SharedResourceLabelType } from "../entity/SharedResourceLabelType";
import { DateTime } from "luxon";
import { getViewport } from "../utils";

function ResourceLabel(props:{label:SharedResourceLabel}){
    switch(props.label.Type){
        case SharedResourceLabelType.Free:{
            return <Badge bg="secondary" >Free</Badge>
        }
        case SharedResourceLabelType.FreeUntil:{
            var until = DateTime.fromISO(props.label.To!);
            return <Badge bg="secondary">Free until {until.toLocaleString(DateTime.TIME_SIMPLE)}</Badge>
        }
        case SharedResourceLabelType.OccupiedUntil:{
            var until = DateTime.fromISO(props.label.To!);
            return <Badge bg="danger" >Occupied until {until.toLocaleString(DateTime.TIME_SIMPLE)}</Badge>
        }
        case SharedResourceLabelType.Yours:{
            var from = DateTime.fromISO(props.label.From!);
            var until = DateTime.fromISO(props.label.To!);
            return <Badge bg="success" >Yours [{from.toLocaleString(DateTime.TIME_SIMPLE)}-{until.toLocaleString(DateTime.TIME_SIMPLE)}]</Badge>
        }
    }
    return <></>
}
function ResourceItem(props: { item: SharedResourceWithLabels, goEdit: (res: SharedResource) => void, goBooking: (res: SharedResource) => void  }) {
    const userReq = useContext(LoggedUserInfoContext);
    const size = getViewport();
    const isMobile = size == 'xs' || size == 'sm';
    return <ListGroup.Item className="pt-1 pb-1">
        <Stack direction="horizontal" gap={1} className="align-items-center flex-wrap">
            <div className="flex-grow-1" style={isMobile?{width:"100%"}:{}}>{props.item.Resource.Name}</div>
            {props.item.Labels.map((l,i)=><h5 className="me-2 mb-0" style={isMobile && i != props.item.Labels.length-1 ? {width:"100%"}:{}}><ResourceLabel label={l}/></h5>)}
            {userReq.isSuccess && <Button onClick={()=>props.goBooking(props.item.Resource)} size="sm" className="me-2 ms-auto">Book</Button>}
            {userReq.isSuccess && userReq.data?.Roles.includes(UserGuildRole.Admin) && <Button onClick={() => props.goEdit(props.item.Resource)} size="sm"><i className="bi bi-pencil"></i></Button>}
        </Stack>
    </ListGroup.Item>
}
function LandItem(props: { item: GuildLandWithResources, goEdit: (res: SharedResource) => void , goBooking: (res: SharedResource) => void }) {

    return <Card className="mb-3">
        <Card.Header>Land #{props.item.LandNo}</Card.Header>
        <ListGroup className="list-group-flush">
            {props.item.LandResources.length > 0 && <>
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">Land</ListGroup.Item>
                {props.item.LandResources.map(q => (<ResourceItem key={q.Resource.Id} item={q} goEdit={props.goEdit} goBooking={props.goBooking} />))}
            </>}
            {props.item.HouseResources.length > 0 && <>
                <ListGroup.Item variant='secondary' className="pt-1 pb-1">House</ListGroup.Item>
                {props.item.HouseResources.map(q => (<ResourceItem key={q.Resource.Id} item={q} goEdit={props.goEdit} goBooking={props.goBooking} />))}
            </>}
        </ListGroup>

    </Card>
}
export default function PlannerPage() {
    const userReq = useContext(LoggedUserInfoContext);
    const landsReq = api.useGetResult<GuildLandWithResources[]>("/planner/planner-list");
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editedResource, setEditedResource] = useState<SharedResource | null>(null);
    const [bookingResource, setBookingResource] = useState<SharedResource | null>(null);

    function handleCreateClick() {
        setCreateModalVisible(true);
    }
    function handleGoEdit(res: SharedResource) {
        setEditedResource(res);
    }
    function handleGoBooking(res: SharedResource){
        setBookingResource(res);
    }
    return <Container>
        {userReq.isSuccess && userReq.data?.Roles.includes(UserGuildRole.Admin) && <div className="mb-3"><Button onClick={handleCreateClick}>Create shared resource</Button></div>}
        {landsReq.isLoading && <>Loading</>}
        {landsReq.error && <>Error {landsReq.error + ' '}</>}
        {landsReq.isSuccess && landsReq.data!.length > 0 && <>
            {landsReq.data?.map(q => (<LandItem key={q.LandNo} item={q} goEdit={handleGoEdit} goBooking={handleGoBooking}/>))}
        </>}
        {landsReq.isSuccess && landsReq.data!.length == 0 && <>No shared resources found!</>}
        {createModalVisible && <CreateSharedResourceModal
            isVisible={createModalVisible}
            onClose={() => setCreateModalVisible(false)}
            onSuccess={() => {
                setCreateModalVisible(false);
                landsReq.reload();
            }}
        />}
        {editedResource && <EditSharedResourceModal
            isVisible={editedResource != null}
            res={editedResource}
            onClose={() => setEditedResource(null)}
            onSuccess={() => {
                setEditedResource(null);
                landsReq.reload();
            }}
        />}
        {bookingResource && <BookingModal
            isVisible={bookingResource != null}
            resource={bookingResource}
            onClose={()=>setBookingResource(null)}
            onCloseWithChanges={()=>{
                setBookingResource(null);
                landsReq.reload();
            }}
        />}
    </Container>;
}