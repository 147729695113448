import { useParams } from "react-router-dom";
import api from "../api";
import CraftingTask from "../entity/CraftingTask";
import TaskSlot from "../entity/TaskSlot";
import { Button, Card, Col, Container, Form, FormGroup, ListGroup, Modal, Placeholder, Row, Table } from "react-bootstrap";
import { resourceConsumedStatuses, resourceProducedStatuses } from "../entity/TaskSlotStatus";
import TaskSlotCard from "./TaskSlotCard";
import { useContext, useState } from "react";
import SpinnableText from "../components/SpinnableText";
import { LoggedUserInfoContext } from "../LoggedUserInfoContext";
import UserGuildRole from "../entity/UserGuildRole";
import CraftingTaskWithStats from "../entity/CraftingTaskWithStats";
import CraftingRecipe from './../entity/CraftingRecipe';
import ExpGainInfo from './../entity/ExpGainInfo';

function ConfirmTaskCloseModal(props: { taskId: string, isVisible: boolean, onClose: () => void, onTaskClosed: () => void }) {
    const [isClosingInProgress, setIsClosingInProgress] = useState(false);
    function handleCloseTask() {
        setIsClosingInProgress(true);
        api.api.performApiRequest(`/taskboard/tasks/${props.taskId}/close`, 'PATCH')
            .then(res => {
                setIsClosingInProgress(false);
                alert('task closed!');
                props.onTaskClosed();
            })
            .catch(err => {
                setIsClosingInProgress(false);
                alert(err);
            });
    }
    function handleOnClose() {
        if (isClosingInProgress)
            return;
        props.onClose();
    }
    return (
        <Modal show={props.isVisible} onHide={handleOnClose}>
            <Modal.Header closeButton>
                <Modal.Title>Closing task</Modal.Title>
            </Modal.Header>
            <Modal.Body>All active slots will be forcefully closed! Confirm?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleCloseTask}>
                    <SpinnableText text="Close task!" isSpinning={isClosingInProgress} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
function CreateRequestModal(props: { isVisible: boolean, onClose: () => void, onRequestMade: () => void, task: CraftingTaskWithStats }) {
    const [isRequestInProgress, setIsRequestInProgress] = useState(false);

    const minQty = Math.min(props.task.Stats.OpenQty, props.task.Task.MinQtyPerRequest);
    const maxQty = Math.min(props.task.Stats.OpenQty, props.task.Task.MaxQtyPerRequest);
    const placeholderText = `min ${minQty} - max ${maxQty}`;
    const [qty, setQty] = useState(maxQty);
    const submitEnabled = minQty <= qty && qty <= maxQty;
    function handleOnClose() {
        if (isRequestInProgress)
            return;
        props.onClose();
    }
    function handleCreateRequest() {
        setIsRequestInProgress(true);
        api.api.performApiRequest('/taskboard/slots/create', 'POST', {
            TaskId: props.task.Task.Id,
            Qty: qty
        })
            .then(res => {
                alert('success');
                props.onRequestMade();
            })
            .catch(err => {
                alert(err);
                setIsRequestInProgress(false);
            })
    }
    return (
        <Modal show={props.isVisible} onHide={handleOnClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create paticipation request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => e.preventDefault()}>
                    <Form.Group
                        className="mb-3"
                    >
                        <Form.Label>Qty</Form.Label>
                        <Form.Control as="input" type="number" placeholder={placeholderText} defaultValue={maxQty} min={minQty} max={maxQty} onChange={(e) => setQty(+e.currentTarget.value)} />
                    </Form.Group>
                    {props.task.Task.RewardPool && <>
                        <FormGroup>
                            <Form.Label>Reward: <b>{Math.floor(props.task.Task.RewardPool.Qty * qty / props.task.Task.Qty)}x{props.task.Task.RewardPool.Name}</b></Form.Label>
                        </FormGroup>
                    </>}
                    {props.task.Task.Recipe.ExpGain && <>
                        <FormGroup>
                            <Form.Label>Exp gain as <b>{props.task.Task.Recipe.ExpGain.Skill}</b>: <b>{props.task.Task.Recipe.ExpGain.ExpGain*qty}</b></Form.Label>
                        </FormGroup>
                    </>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" disabled={isRequestInProgress} onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button variant="primary" disabled={!submitEnabled||isRequestInProgress} onClick={handleCreateRequest}>
                    <SpinnableText text="Create request" isSpinning={isRequestInProgress} />
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default function TaskPage() {
    const userReq = useContext(LoggedUserInfoContext);
    //get task id
    const { taskId } = useParams();
    const taskReq = api.useGetResult<CraftingTaskWithStats>("/taskboard/tasks/" + taskId);
    const slotsReq = api.useGetResult<TaskSlot[]>("/taskboard/tasks/" + taskId + "/slots");
    const [isCloseTaskModalVisible, setIsCloseTaskModalVisible] = useState(false);
    const [isCreateRequestModalVisible, setIsCreateRequestModalVisible] = useState(false);
    function handleCloseTaskButtonClick() {
        setIsCloseTaskModalVisible(true);
    }
    function handleCreateRequestButtonClick() {
        setIsCreateRequestModalVisible(true);
    }
    function reloadData() {
        taskReq.reload();
        slotsReq.reload();
    }
    if (taskReq.isLoading || slotsReq.isLoading) {
        return (
            <Container className="mt-3">
                Loading data
            </Container>
        );
    }
    if (taskReq.error || slotsReq.error) {
        var err = taskReq.error ?? slotsReq.error;
        return (
            <Container className="mt-3">
                Error: {err + ''}
            </Container>
        );
    }
    const task = taskReq.data!.Task;
    const slots = slotsReq.data!;
    const totalConsumedQty = slots
        .filter(q => resourceConsumedStatuses.includes(q.Status))
        .map(q => q.Qty)
        .reduce((q, e) => q + e, 0);
    const totalProducedQty = slots
        .filter(q => resourceProducedStatuses.includes(q.Status))
        .map(q => q.Qty)
        .reduce((q, e) => q + e, 0);
    const totalQty = task.Qty;

    return (
        <Container className="mt-1" style={{ maxWidth: 960 }}>
            <h2 style={{ marginBottom: -10 }}>Task info</h2>
            <Row>
                <Col className="mt-3">
                    <Card className="task-info-card">
                        <Card.Header>
                            <div className='d-flex flex-row align-items-center' style={{ height: '36px' }}>
                                {/* <img src='https://placehold.it/32x32'/> */}
                                <div>
                                    <img className="item-icon me-2" src={`/item_images/${task.Recipe.Output.ItemId}.png`}/>
                                    {task.Qty}x<b>{task.Recipe.Output.Qty>1 && <>{task.Recipe.Output.Qty}x</>}{task.Recipe.Output.Name}</b>
                                </div>
                                <div className='ms-auto'>by <b>{task.MakerUser.Username}</b></div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <h5>Ingredients</h5>
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="item-name">Item</th>
                                            <th>Used qty</th>
                                            <th>To use qty</th>
                                            <th>Total qty</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {task.Recipe.Input.map(q => (
                                            <tr>
                                                <td className="item-name"><img className="item-icon me-2" src={`/item_images/${q.ItemId}.png`}/>{q.Name}</td>
                                                <td>{totalConsumedQty * q.Qty}</td>
                                                <td>{(totalQty - totalConsumedQty) * q.Qty}</td>
                                                <td>{totalQty * q.Qty}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            {task.RewardPool && <>
                                <h5>Reward pool</h5>
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="item-name">Item</th>
                                            <th>Used qty</th>
                                            <th>Left qty</th>
                                            <th>Total qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td className="item-name"><img className="item-icon me-2" src={`/item_images/${task.RewardPool.ItemId}.png`}/>{task.RewardPool.Name}</td>
                                            <td>{taskReq.data!.Stats.RewardsConsumed}</td>
                                            <td>{taskReq.data!.Stats.RewardsLeft}</td>
                                            <td>{task.RewardPool.Qty}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                            </>}
                            <h5>Results</h5>
                            <div className="table-responsive">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className="item-name">Item</th>
                                            <th>Made qty</th>
                                            <th>To make qty</th>
                                            <th>Total qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td className="item-name"><img className="item-icon me-2" src={`/item_images/${task.Recipe.Output.ItemId}.png`}/>{task.Recipe.Output.Name}</td>
                                            <td>{totalProducedQty * task.Recipe.Output.Qty}</td>
                                            <td>{(totalQty - totalProducedQty) * task.Recipe.Output.Qty}</td>
                                            <td>{totalQty * task.Recipe.Output.Qty}</td>
                                        </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <div className="float-end">
                                {(task.Closed || task.MakerUser.Id != userReq?.data?.Id) && <Button variant="secondary" style={{ visibility: "hidden" }}>No actions</Button>}
                                {!task.Closed && task.MakerUser.Id == userReq?.data?.Id && <><Button variant="danger" onClick={handleCloseTaskButtonClick}>Close</Button>{' '}</>}
                                {!task.Closed && task.MakerUser.Id != userReq?.data?.Id && userReq?.data?.Roles.includes(UserGuildRole.Member) && <Button disabled={taskReq.data!.Stats.OpenQty==0} onClick={handleCreateRequestButtonClick} variant="success">Participate</Button>}
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>
                {/* <Col xs={12} lg={4} className="mt-3">
                    <Card>
                        <Card.Header>Some otherinfo</Card.Header>
                        <ListGroup className="list-group-flush">
                            <ListGroup.Item>abc</ListGroup.Item>
                            <ListGroup.Item>def</ListGroup.Item>
                            <ListGroup.Item>xyz</ListGroup.Item>
                        </ListGroup>
                    </Card>

                </Col> */}
            </Row>
            <Row className="mt-3" style={{ marginBottom: -10 }}>
                <h2>Slot requests</h2>
            </Row>
            <Row xs={1} lg={2}>
                {slots.map(q => (
                    <Col className="mt-3">
                        <TaskSlotCard key={q.Id} slot={q} task={task} onChanged={reloadData} />
                    </Col>
                ))}
            </Row>
            <ConfirmTaskCloseModal
                taskId={task.Id}
                onClose={() => setIsCloseTaskModalVisible(false)}
                onTaskClosed={() => {
                    reloadData();
                    setIsCloseTaskModalVisible(false);
                }}
                isVisible={isCloseTaskModalVisible} />
            {isCreateRequestModalVisible && <CreateRequestModal
                onClose={() => setIsCreateRequestModalVisible(false)}
                onRequestMade={() => {
                    reloadData();
                    setIsCreateRequestModalVisible(false);
                }}
                task={taskReq.data!}
                isVisible={isCreateRequestModalVisible}
            />}
        </Container>
    )

}