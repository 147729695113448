import { Button, Container, Form, ListGroup, Modal } from "react-bootstrap";
import api from "../api";
import UserInfo from "../entity/UserInfo";
import { useState } from "react";
import SpinnableText from "../components/SpinnableText";
import UserRow from "./UserRow";
import { sha256 } from "js-sha256";
import SetPasswordModal from "./SetPasswordModal";

export default function UsersPage() {

    const usersRequest = api.useGetResult<UserInfo[]>('/users/list');
    const [setPasswordModalVisible, setSetPasswordModalVisible] = useState(false);
    const [editedUserId, setEditedUserId] = useState<string>("");
    function handleUserPasswordChanged() {
        setSetPasswordModalVisible(false);
        usersRequest.reload();
    }
    function handleChangePasswordClick(userId: string) {
        setEditedUserId(userId);
        setSetPasswordModalVisible(true);
    }
    function handleRowChanged(reloadedCallback: () => void) {
        usersRequest.reloadWithCallback(reloadedCallback);
    }
    return (
        <Container className="mt-2">
            <h1>User list</h1>
            <ListGroup>
                {usersRequest.isLoading && <ListGroup.Item>Loadin...</ListGroup.Item>}
                {usersRequest.error && <ListGroup.Item>Failed to load</ListGroup.Item>}
                {!usersRequest.isLoading && !usersRequest.error && <>
                    {usersRequest.data?.map((u) =>
                        <UserRow
                            key={u.Id}
                            userInfo={u}
                            onChanged={handleRowChanged}
                            onChangePasswordClick={handleChangePasswordClick} />)}
                </>}
            </ListGroup>
            <SetPasswordModal
                isVisible={setPasswordModalVisible}
                onClose={() => { setSetPasswordModalVisible(false) }}
                onSuccess={handleUserPasswordChanged}
                userId={editedUserId}
            />
        </Container>)
}