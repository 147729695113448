import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import api from "../api";
import SpinnableText from "../components/SpinnableText";
import SharedResource from "../entity/SharedResource";

export default function EditSharedResourceModal(props: { isVisible: boolean, res:SharedResource, onClose: () => void, onSuccess: () => void }) {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [name, setName] = useState<string | null>(props.res.Name);
    const [isLoading, setIsLoading] = useState(false);
    function handleUpdate() {
        setIsLoading(true);
        api.api.performApiRequest(`/planner/shared-resource/${props.res.Id}`, 'PATCH', {
            Name:name
        })
        .then(res=>{
            setIsSuccess(true);
            setIsClosing(true);
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        })
    }
    function handleDelete(){
        setIsLoading(true);
        api.api.performApiRequest(`/planner/shared-resource/${props.res.Id}`, 'DELETE')
        .then(res=>{
            setIsSuccess(true);
            setIsClosing(true);
        })
        .catch(err=>{
            alert(err);
            setIsLoading(false);
        })
    }
    return (<Modal show={props.isVisible && !isClosing} onHide={() => setIsClosing(true)} onExited={()=>isSuccess?props.onSuccess():props.onClose()}>
        <Modal.Header closeButton>
            Edit shared resource
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={e => e.preventDefault()}>
                <Form.Group className="mb-3">
                    <Form.Label>Land</Form.Label>
                    <Form.Control disabled value={`#${props.res.LandNo}`} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control disabled={isLoading} placeholder="farming area 1" defaultValue={props.res.Name} onChange={(q) => setName(q.currentTarget.value)} />
                </Form.Group>
                <Form.Group className="mb-3" >
                    <Form.Group className="mb-3">
                        <Form.Check type="switch" disabled label="Is in house" checked={props.res.InHouse}/>
                    </Form.Group>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" disabled={isLoading } onClick={handleUpdate}>
                <SpinnableText isSpinning={isLoading} text="Update" />
            </Button>
            <Button variant="danger" disabled={isLoading } onClick={handleDelete}>
                <SpinnableText isSpinning={isLoading} text="Delete" />
            </Button>
        </Modal.Footer>
    </Modal>)
}