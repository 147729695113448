import { ReactElement, RefAttributes, useRef, useState } from "react"
import { Dropdown } from "react-bootstrap"

interface DropdownWithSearchProps{
    items:any[]
    itemRender: (item:any)=>ReactElement
    itemToButtonRender?: ((item:any)=>ReactElement)
    itemToKey: (item:any)=>string
    itemFilter: (item:any, filter:string)=>boolean
    placeholderText:string,
    disabled:boolean,
    onSelect: (key:string)=>void,
    toggleVariant?:string|undefined
}

export default function DropdownWithSearch(props:DropdownWithSearchProps){
    var toButtonRender = props.itemToButtonRender??props.itemRender;
    const [filterText, setFilterText] = useState('');
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const filterInputRef = useRef<HTMLInputElement>(null);
    function handleToggle(show:boolean){
        if(show){
            setTimeout(() => {
                filterInputRef.current?.focus();
            }, 1);
        }
    }

    function handleDropdownSelect(key:string|null){
        const sel = props.items.find(q=>props.itemToKey(q) === key);
        setSelectedItem(sel);
        if(key != null){
            props.onSelect(key);
        }
    }
    return (
        <Dropdown onSelect={handleDropdownSelect} onToggle={handleToggle}>
            <Dropdown.Toggle disabled={props.disabled} variant={props.toggleVariant} style={{width:"100%"}}>
                {!selectedItem && <>{props.placeholderText}</>}
                {selectedItem && <>{toButtonRender(selectedItem)}</>}

            </Dropdown.Toggle>
            <Dropdown.Menu style={{width:"100%"}}>
                <input
                    type="text"
                    className="form-control"
                    style={{ margin: "8px 10px", width: "calc(100% - 20px)", borderRadius: "5px" }}
                    placeholder={props.placeholderText}
                    onChange={(e) => setFilterText(e.target.value)}
                    ref={filterInputRef}
                />
                <div style={{height:"auto", maxHeight:"200px", overflowX:"hidden"}}>
                    {
                        props.items
                            .filter((q) => filterText == '' || props.itemFilter(q, filterText))
                            .slice(0,50)
                            .map((item) => (
                                <Dropdown.Item key={props.itemToKey(item)} eventKey={props.itemToKey(item)}>
                                    {<>{props.itemRender(item)}</>}
                                </Dropdown.Item>
                            ))
                    }
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
}